import React, { Component } from "react";

import Logo from "../Logo";
import PageTitle from "../PageTitle";
import Farewell from "../Farewell";
import Footer from "../Footer";
import Link from "../Link";
import Timeline, { Level, Container } from "../Timeline";

import { LINKS } from '../../enums';

import "./styles.scss";

// assets
import paperRollImage from "../../assets/images/paper-roll.png";
import stringImage from "../../assets/images/string.png";
import finalTouchesImage from "../../assets/images/final-touches.png";

import paperHeading from "../../assets/headings/paper.png";
import stringHeading from "../../assets/headings/string.png";
import finalTouches from "../../assets/headings/final-touches.png";

const renderListItems = (linkData) => {
  return linkData.map(linkData => (
    <li><Link {...linkData}/></li>  
  ))
};

class App extends Component {
  render() {
    return (
      <div className="app">
        <Logo isLink />
        <PageTitle
          header="GIFT WRAPPING RESOURCES"
          subtitle="from Forest Culture Design"
        />
        
        <Timeline>
          <Level>
            <Container direction="left">
              <img className="container-hero paper-hero" alt="paper" src={paperRollImage} />
            </Container>
            <Container direction="right">
              <img
                className="container-header-image"
                alt="paper"
                src={paperHeading}
              />
              <div className="fcd-links__container">
                <ul className='fcd-links__list'>
                  <li>Recycled Brown Paper Grocery Bags</li>
                  <li>
                    Brown Kraft Paper (<Link href='#' text='17.75in' /> / <Link href='#' text='30in' />)
                  </li>
                  {renderListItems(LINKS.PAPER_LINKS.section1)}
                </ul>
                <span className='fcd-links__subheader'>TO DECORATE YOUR PAPER</span>
                <ul className='fcd-links__list'>
                  {renderListItems(LINKS.PAPER_LINKS.section2)}
                </ul>
              </div>
            </Container>
          </Level>

          <Level className='middle-level'>
            <Container direction="left">
              <img
                className="container-header-image"
                alt="string"
                src={stringHeading}
              />
              <div className="fcd-links__container">
                <ul className='fcd-links__list'>
                  {renderListItems(LINKS.STRING_LINKS.section1)}
                </ul>
              </div>
            </Container>
            <Container direction="right">
              <img className="container-hero string-hero" alt="paper" src={stringImage} />
            </Container>
          </Level>

          <Level>
            <Container direction="left">
              <img className="container-hero final-hero" alt="paper" src={finalTouchesImage} />
            </Container>
            <Container direction="right">
              <img
                className="container-header-image"
                alt="final"
                src={finalTouches}
              />

              <div className="fcd-links__container">
                <ul className='fcd-links__list'>
                  {renderListItems(LINKS.FINAL_LINKS.section1)}
                </ul>
                <span className='fcd-links__subheader'>USE WHAT YOU HAVE!</span>
                <ul className='fcd-links__list'>
                  <li>Fabric Scraps</li>
                  <li>Scraps of Colored Paper</li>
                  <li>Recycled Ribbons from Other Gifts</li>
                  <li>News Print</li>
                  <li>Evergreen Clippings</li>
                </ul>
              </div>
            </Container>
          </Level>
        </Timeline>
        <Farewell message="Happy Wrapping!" />
        <Footer />
      </div>
    );
  }
}
export default App;
