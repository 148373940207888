import React from 'react';

import { LINKS } from '../../enums';

import logo from '../../assets/images/logo.png';
import './styles.scss';

const Logo = ({ isLink }) => {
    
    if (isLink) {
        return (
            <div className='fcd-logo'>
                <a href={LINKS.FCD_STORE}>
                    <img className='fcd-logo__img' alt='Forest Culture Design' src={logo} />
                </a>
            </div>
        )
    } else {
        return (
            <div className='fcd-logo'>
                <img className='fcd-logo__img' alt='Forest Culture Design' src={logo} />
            </div>
        )
    }
};

export default Logo