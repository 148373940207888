import React from 'react';

import './styles.scss';

const PageTitle = ({ header, subtitle }) => {
    return (
        <div className='fcd-title'>
            <div className='fcd-title__header'>
                {header}
            </div>
            <div className='fcd-title__subtitle'>
                {subtitle}
            </div>
        </div>
    )
};

export default PageTitle;