import React from 'react';
import classnames from 'classnames';

import { LINKS } from '../../enums';

import './styles.scss';

export default Footer => {
    const currentYear = new Date().getFullYear();
    const amazonCopy = 'Contains affiliate links. As an Amazon Associate I earn from qualifying purchases.';
    const footerLinkClassNames = classnames(
        'fcd-footer__copyright--link', 
        'no-style-link'
    );

    return (
        <div className="fcd-footer">
            <div className="fcd-footer__copyright">
                © <a className={footerLinkClassNames} href={LINKS.FCD_STORE}>Forest Culture Design</a> {currentYear}
            </div>
            <div className="fcd-footer__amazon">
                {amazonCopy}
            </div>
        </div>
    )
};