import React from 'react';

import './styles.scss';

const Farewell = ({ message }) => (
    <div className='fcd-farewell'>
        {message}
    </div>
);

export default Farewell;
