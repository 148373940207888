const FCD_STORE = 'https://www.etsy.com/shop/ForestCulture';

const PAPER_LINKS = {
    section1: [
        { text: 'White Paper Roll', href: 'https://www.etsy.com/listing/452134852/wrapping-paper-roll-gift-wrap-30-feet-x?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=white+paper+roll&ref=sr_gallery-1-1&organic_search_click=1&frs=1' },
        { text: 'Black Kraft Paper', href: 'https://www.etsy.com/listing/190970690/8-ft-chalkboard-paper-roll-black-kraft?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=black+kraft+paper+roll&ref=sc_gallery-1-3&plkey=b588a7ec91ba2583d65b022ded470c80afa0b6e4%3A190970690'},
        { text: 'Kraft Paper - Other Colors', href: 'https://www.amazon.com/gp/product/B004R4KWSU/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B004R4KWSU&linkCode=as2&tag=forestculture-20&linkId=db8c11279320426f88fe19092c92cf69'},
    ],
    section2: [
        { text: 'Paint', href: 'https://www.amazon.com/gp/product/B00CIW3VWW/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B00CIW3VWW&linkCode=as2&tag=forestculture-20&linkId=859d5ce7127b54f4458d5f794324cac9'},
        { text: 'Foam for Making Your Own Stamps', href: 'https://www.amazon.com/gp/product/B003AXYBK4/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B003AXYBK4&linkCode=as2&tag=forestculture-20&linkId=3023f637a8ebbd0ae7af865a33e1484b'},
        { text: 'Foam Brushes', href: 'https://www.amazon.com/gp/product/B001BF6MDM/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B001BF6MDM&linkCode=as2&tag=forestculture-20&linkId=403ab3f47ce4b3dc67684e8ebe4b79a1'},
        { text: 'Paint Pens', href: 'https://www.amazon.com/gp/product/B003YDYO1Q/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B003YDYO1Q&linkCode=as2&tag=forestculture-20&linkId=d8f8f2a49e338b36d7313ee3fbe4df6c'},
    ]
};

const STRING_LINKS = {
    section1: [
        { text: 'Hemp/Jute Twine', href: 'https://www.amazon.com/gp/product/B07BC63QST/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B07BC63QST&linkCode=as2&tag=forestculture-20&linkId=28af439f53f9af5654da9cb93059694e'},
        { text: 'Baker’s Twine', href: 'https://www.etsy.com/listing/622486024/just-artifacts-striped-bakers-twine?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=bakers+twine&ref=sr_gallery-1-23&organic_search_click=1&frs=1&col=1'},
        { text: 'Rafia', href: 'https://www.amazon.com/gp/product/B072XBTGHN/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B072XBTGHN&linkCode=as2&tag=forestculture-20&linkId=89df06d951e9fdfd0cc8247668e8b0b3'},
        { text: 'Cotton Twine', href: 'https://www.amazon.com/gp/product/B06XR58J3H/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B06XR58J3H&linkCode=as2&tag=forestculture-20&linkId=e0dd27e9b698efba04ac97eed77afa7a'},
    ]
};

const FINAL_LINKS = {
    section1: [
        { text: 'Floral Wire for Greenery Wreathes', href: 'https://www.amazon.com/gp/product/B001K7QAYM/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B001K7QAYM&linkCode=as2&tag=forestculture-20&linkId=7cde7fe2bb98d517ffa0dbe9555c20d8'},
        { text: 'Ribbon', href: 'https://www.etsy.com/listing/462988811/hand-ripped-fabric-ribbon-100-cotton-9?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=cotton+ribbon&ref=sr_gallery-1-16&organic_search_click=1&col=1'},
        { text: 'Wide Ribbon', href: 'https://www.etsy.com/listing/636689287/3-yds-raw-muslin-ribbon-muslin-ribbon?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=cotton+ribbon&ref=sr_gallery-1-34&organic_search_click=1&col=1'},
        { text: 'Ready Made Gift Tags', href: 'https://www.etsy.com/listing/384338736/black-mix-parcel-tag-trio-box?ga_search_query=gift%2Btags&ref=shop_items_search_43&ep_click=1&pro=1&frs=1'},
        { text: 'Hole Reinforcement Stickers', href: 'https://www.etsy.com/listing/193186267/brown-kraft-hole-reinforcement-stickers?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=kraft+hole+reinforcement+stickers&ref=sr_gallery-1-3&organic_search_click=1&pro=1'},
    ]
};

const LINKS = {
    FCD_STORE,
    PAPER_LINKS,
    STRING_LINKS,
    FINAL_LINKS
};


export {
    LINKS
}