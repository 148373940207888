import React from 'react';

import Container from './Container';
import Level from './Level';

import './styles.scss';

const Timeline = ({ children })=> (
  <section id="conference-timeline">
    <div className="conference-center-line" />
    <div className="conference-timeline-content">
        {children}
    </div>
  </section>
);

export { Container, Level };
export default Timeline;