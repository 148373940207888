import React from 'react';
import classnames from 'classnames';

import './styles.scss';

const Container = ({ direction, children}) => {
    const innerClassName = classnames({
        'content-left': direction === 'left',
        'content-right': direction === 'right'
    });
    const outerClassName = classnames({
        'content-left-container': direction === 'left',
        'content-right-container': direction === 'right'
    });

    return (
        <div className={outerClassName}>
            <div className={innerClassName}>
                <p>{children}</p>
            </div>
        </div>
    );
};

export default Container