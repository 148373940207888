import React from 'react';
import classnames from 'classnames'

import './styles.scss';

const Level = ({ children, className }) => {
    const articleClassName = classnames(className, 'timeline-article')
    
    return (
        <div className={articleClassName}>
            {children}
            <div className="marker" />
        </div>
    );
};

Level.defaultProps = {
    className: ''
};

export default Level