import React, { Fragment } from 'react';
import MediaQuery from 'react-responsive';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowCircleRight } from '@fortawesome/free-solid-svg-icons';

import "./styles.scss";

const MOBILE_BREAK = 700;

const Link = ({ href, text, forceNewTab}) => {
    const newTab = forceNewTab ? '_blank' : null;

    return (
        <Fragment>
            <MediaQuery maxWidth={MOBILE_BREAK}>
                <a target={newTab} className='fcd-links__link' href={href}>{text}<FontAwesomeIcon icon={faArrowCircleRight} /></a>
            </MediaQuery>

            <MediaQuery minWidth={MOBILE_BREAK + 1}>
                <a target={newTab} href={href}>{text}</a>
            </MediaQuery>
        </Fragment>
    )
};

Link.defaultProps = {
    href: '#',
    text: 'placeholder',
    forceNewTab: true
}

export default Link